/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FlexparkService from '../services/FlexparkService';

export const getUserFlexpark = createAsyncThunk(
  'payment/getUserFlexpark',
  async ({ id }) => {
    try {
      const response = await FlexparkService.getUserFlexpark({ id });
      const data = await response.data;
      if (response.status !== 200) {
        throw new Error(data.failure);
      }
      return data;
    } catch (e) {
      throw new Error('An error Occured. Try again later');
    }
  }
);

export const getFlexparkParkingSessions = createAsyncThunk(
  'payment/getFlexparkParkingSessions',
  async () => {
    try {
      const response = await FlexparkService.getFlexparkParkingSessions();
      const data = await response.data;
      if (response.status !== 200) {
        throw new Error(data.failure);
      }
      return data;
    } catch (e) {
      throw new Error('An error Occured. Try again later');
    }
  }
);

export const stripeSlice = createSlice({
  name: 'flexpark',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: {
    [getUserFlexpark.pending]: (state) => {
      state.loading = true;
    },
    [getUserFlexpark.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getUserFlexpark.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getFlexparkParkingSessions.pending]: (state) => {
      state.loading = true;
    },
    [getFlexparkParkingSessions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getFlexparkParkingSessions.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default stripeSlice.reducer;
