import ApiService from './ApiService';

export default class LocationService {

  static createLocations = ({
    name,
    operatorId,
    bio,
    merchant_id,
    merchant_dba,
    operatedBy,
    description,
    company_description,
    how_to_redeem,
    refund_policy,
    parkingType,
    images,
    timeZone,
    amenities,
    numberOfSpaces,
    availableNumberOfSpaces,
    agentUid,
    ballparcLocationId,
    facilityType,
    locationCode,
    validationType,
    openPathAccessCode,
    systemNameOpenPath,
    address: {
      street,
      country,
      state,
      city,
      zip,
      latitude,
      longitude,
      entranceLatitude,
      entranceLongitude,
    },
    locationId,
    published,
    enableReservation,
    reservationLimit,
    hideDisplay,
    reservationLimitFrequency,
    include_convenience_time_in_parking_session,
    convenience_time,
    grace_period,
    qrCodeAccess,
    accessPinCode, 
    doorAccessPin,
    accessExpiry,
    operator_ids,
    cameras,
    zones,
  }) => {

    const mappedData = {
      name,
      operator_id: parseInt(operatorId),
      bio,
      merchant_id: merchant_id,
      merchant_dba: merchant_dba,
      operated_by: operatedBy,
      description,
      company_description,
      refund_policy,
      how_to_redeem,
      validation_type: validationType,
      facility_type: facilityType,
      location_code: locationCode,
      parking_type: parkingType,
      image_path: images,
      time_zone: timeZone,
      amenities,
      number_of_spaces: numberOfSpaces,
      available_number_of_spaces: availableNumberOfSpaces,
      agent_uid: agentUid.map(field => field?.lprAgentId),
      ballparc_location_id: ballparcLocationId,
      open_path_credential:openPathAccessCode,
      open_path_credential_type_id:systemNameOpenPath,
      address: {
        street,
        country,
        state,
        city,
        zip,
        latitude,
        longitude,
        entrance_latitude: entranceLatitude,
        entrance_longitude: entranceLongitude,
      },
      published: published || false,
      enable_reservation: enableReservation || false,
      reservation_limit: reservationLimit,
      hide_display: hideDisplay || false,
      reservation_limit_frequency: reservationLimitFrequency,
      include_convenience_time_in_parking_session,
      convenience_time,
      grace_period,
      qr_code_access: qrCodeAccess || false,
      access_pin_code: accessPinCode || false, 
      door_access_pin: doorAccessPin,
      access_expiry: accessExpiry,
      operator_ids: operator_ids.map((opr) => opr.value),
      cameras,
      zones,
    };
    if (typeof locationId !== 'undefined') {
      return ApiService.put(`/locations/${locationId}`, mappedData);
    } else {
      return ApiService.post('/locations', mappedData);
    }
  };

  static getLocation = async (locationSlug) =>
    ApiService.get(`/locations/${locationSlug}`);

  static getAllLocations = async () => ApiService.get('/locations');

  // static getOLocation = async (operatorId) =>
  //   ApiService.get(`/operators/${operatorId}`);

  static getLocationAssets = async (locationSlug, filters = {}) =>
    ApiService.get(`/locations/${locationSlug}/assets`, filters);

  static getAvailableParkingSpots = async (query = '') =>
    ApiService.get(`/search/${query}`);

  static getLocationAsset = async (locationSlug, assetId) =>
    ApiService.get(`/locations/${locationSlug}/assets/${assetId}`);

  static getLocationsInventory = async ({locationId, startDate, endDate}) =>
    ApiService.get(`/locations/parking_sessions/${locationId}?start_date=${startDate}&end_date=${endDate}`);

  /**
   * Update a project
   *
   * @param {string} locationSlug
   * @param {{
   *  name?: string;
   *  description?: string;
   *  isVisible?: boolean;
   *  isOpen?: boolean;
   *  isZoomEnalbed?: boolean;
   *  isCopyAllowed?: boolean;
   *  thumbnailAssetId?: string;
   *  thumbnailType?: string;
   * }} data
   * @returns
   */
  static updateLocation = async (locationSlug, data) =>
    ApiService.patch(`/locations/${locationSlug}`, data);

  static updateBallparcLocation = async (locationSlug, data) => 
  ApiService.patch(`/locations/set_ballparc_id/${locationSlug}`, data);

  static deleteLocation = (locationId) =>
    ApiService.delete(`/locations/${locationId}`);

  /**
   * Update the project roles for a user or team
   * @param {string} locationId
   * @param {string} userTeamId Can be either a user id or a team id
   * @param {number[]} roles
   * @returns
   */
  static updateUserOrTeamRoles = async (locationSlug, userTeamId, roles) =>
    ApiService.post(`/location/${locationSlug}/roles/${userTeamId}`, { roles });

  static findSearch = (findSearch) =>
    ApiService.get('/find_location', findSearch);

  static getLocationsHistory = async ({ locationId, timezoneOffset }) => ApiService.get(`locations/changes_history/${locationId}?timezone_offset=${timezoneOffset}`);

  static getCameraDetails = async ({ agent_uid, location_id }) => ApiService.get(`/cameras/agent_camera/${agent_uid}/${location_id}`);

  static getAllZones = async (zone_id) => ApiService.get(`/location/zones/${zone_id}`);

  static getBallparcLocations = async (operatorId) => ApiService.get(`/ballparc/locations/${operatorId}`);

}
