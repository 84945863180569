/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SupportService from '../services/SupportService';

export const support = createAsyncThunk(
  'support/supportMessage',
  async ({ username:name, contact:contact_no, email, notes}) => {
   
    try {
      const response = await SupportService.support({
        name,
        contact_no,
        email,
        notes,
        
      });
      const data = await response.data;
      
      return data;
    } catch (e) {
      throw new Error('An error Occured. Try again later');
    }
  }
);

export const supportSlice = createSlice({
  name: 'support',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: {
    [support.pending]: (state) => {
      state.loading = true;
    },
    [support.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [support.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default supportSlice.reducer;
