/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SubscriptionService from "../services/SubscriptionService";

export const createSubscription = createAsyncThunk(
  "subscription/createSubscription",
  async ({
    published,
    rateName,
    price,
    duration,
    prorate,
    rateType,
    overnightRate,
    availableStart,
    availableEnd,
    availableForPurchase,
    locationId,
    rateId,
  }) => {
    try {
      const response = await SubscriptionService.createSubscription({
        published,
        rateName,
        price,
        duration,
        prorate,
        rateType,
        overnightRate,
        availableStart,
        availableEnd,
        availableForPurchase,
        locationId,
        rateId,
      });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        if (typeof rateId !== "undefined") {
          data["rateId"] = rateId;
        }
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const editSubscription = createAsyncThunk(
  "subscription/editSubscription",
  async ({ vehicle_license_number, id, ssl_token }) => {
    try {
      const response = await SubscriptionService.editSubscription({
        vehicle_license_number,
        id,
        ssl_token
      });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        if (typeof id !== "undefined") {
          data["id"] = id;
        }
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getSubscriptions = createAsyncThunk(
  "subscription/getSubscriptions",
  async () => {
    const response = await SubscriptionService.getSubscriptions();
    const data = response.data;
    return data;
  }
);

export const getUserFlexpasses = createAsyncThunk(
  "subscription/getUserFlexpasses",
  async () => {
    const response = await SubscriptionService.getUserFlexpasses();
    const data = response.data;
    return data;
  }
);

export const getMonthlySubscriptions = createAsyncThunk(
  "subscription/getMonthlySubscriptions",
  async ({ user_id }) => {
    const response = await SubscriptionService.getMonthlySubscriptions({
      user_id,
    });
    const data = response.data;
    return data;
  }
);

export const getUserActiveSubscription = createAsyncThunk(
  "subscription/getUserActiveSubscription",
  async ({ user_id }) => {
    const response = await SubscriptionService.getUserActiveSubscription({
      user_id,
    });
    const data = response.data;
    return data;
  }
);

export const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async ({ subscription_id }) => {
    const response = await SubscriptionService.getSubscription({
      subscription_id,
    });
    const data = response.data;
    return data;
  }
);

export const cancelActiveSubscription = createAsyncThunk(
  "subscription/cancelActiveSubscription",
  async ({ id, ssl_recurring_id }) => {
    const response = await SubscriptionService.cancelActiveSubscription({
      id,
      ssl_recurring_id,
    });
    const data = response.data;
    return data;
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    loading: false,
    error: null,
    data: [],
    subscription: null,
  },
  extraReducers: {
    [createSubscription.pending]: (state) => {
      state.loading = true;
    },
    [createSubscription.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [createSubscription.fulfilled]: (state, action) => {
      if (typeof action.payload.rateId !== "undefined") {
        state.loading = false;
        let subscription = state.data.filter(
          (subs) => subs.id !== action.payload.rateId
        );
        subscription.push(action.payload.subscription);
        state.data = subscription;
      } else if (typeof action.payload.message !== "undefined") {
        state.loading = false;
      } else {
        state.loading = false;
        state.data.push(action.payload.data.rate);
      }
    },
    [editSubscription.pending]: (state) => {
      state.loading = true;
    },
    [editSubscription.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [editSubscription.fulfilled]: (state, action) => {
      state.loading = false;
      state.subscription = action.payload;
    },
    [getSubscriptions.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getSubscriptions.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [cancelActiveSubscription.pending]: (state) => {
      state.loading = true;
    },
    [cancelActiveSubscription.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [cancelActiveSubscription.fulfilled]: (state) => {
      state.loading = false;
    },
    [getUserFlexpasses.pending]: (state) => {
      state.loading = true;
    },
    [getUserFlexpasses.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getUserFlexpasses.fulfilled]: (state, action) => {
      state.loading = false;
      state.flexpasses = action.payload;
    },
    [getMonthlySubscriptions.pending]: (state) => {
      state.loading = true;
    },
    [getMonthlySubscriptions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getMonthlySubscriptions.fulfilled]: (state, action) => {
      state.loading = false;
      state.monthlySubscriptions = action.payload;
    },
    [getUserActiveSubscription.pending]: (state) => {
      state.loading = true;
    },
    [getUserActiveSubscription.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getUserActiveSubscription.fulfilled]: (state, action) => {
      state.loading = false;
      state.activeSubscriptions = action.payload;
    },
    [getSubscription.pending]: (state) => {
      state.loading = true;
    },
    [getSubscription.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getSubscription.fulfilled]: (state, action) => {
      state.loading = false;
      state.subscription = action.payload;
    },
  },
});

export default subscriptionSlice.reducer;
