import ApiService from "./ApiService";

export default class IntegrationsService {
  static createIntegration = ({
    systemName,
    operators,
    locations,
    integrationTypeCd,
    passportOperatorId,
    passportClient,
    passportSecret,
    passportZoneId,
    enabled,
    apiKey,
    apiSecret,
    integrationId,
    integrationsId,
    parisCustomerCode,
    parisUserId,
    parisUserPassword,
    entryId,
    orgId,
    // systemNameOpenPath,
    password,
    entryName,
    email,
    userId,
    open_path_entry_id_one,
    open_path_entry_name_one
  }) => {
    let mappedData = {
      integration: {
        system_name: systemName?.value,
        operator_id: operators?.value,
        location_id: locations?.value,
        enabled,
        integration_type_cd: integrationTypeCd?.value,
        authorization_body: null,
        passport_operator_id: passportOperatorId,
        passport_client: passportClient,
        passport_secret: passportSecret,
        passport_zone_id: passportZoneId,
        api_key: apiKey,
        api_secret: apiSecret,
        paris_customer_code: parisCustomerCode,
        paris_user_id: parisUserId,
        paris_user_password: parisUserPassword,
        open_path_email:email,
        open_path_password:password,
        open_path_entry_id:entryId,
        open_path_org_id:orgId,
        open_path_user_id:userId,
        open_path_entry_name:entryName,
        open_path_entry_id_one:open_path_entry_id_one,
        open_path_entry_name_one:open_path_entry_name_one

      },
    };
    if (typeof integrationsId !== "undefined") {
      return ApiService.put(`/integrations/${integrationsId}`, mappedData);
    } else {
      return ApiService.post("/integrations", mappedData);
    }
  };

  static getIntegrations = () => ApiService.get("/integrations");
  static getSingleIntegrations = ({integrationsId}) => ApiService.get(`/integrations/${integrationsId}`);

  static getFormValues = () => ApiService.get("/integration/form_values");

  static deleteIntegration = (id) => ApiService.delete(`/integrations/${id}`);

  static updateIntegration = async (id, data) =>
    ApiService.patch(`/integrations/${id}`, data);
}
