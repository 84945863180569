import ApiService from "./ApiService";

export default class PromoCodeService {
  static createPromocode = ({
    promoCode: code,
    promocodeDescription: description,
    discountType: discount_type,
    usageType: usage_limit_type,
    promoCodeCount: promo_code_count,
    maxTotalUsage: max_total_uses,
    maxFrequency: max_frequency_limit,
    discountAmount: value,
    discountPercentage: percent,
    startDate: start_date,
    endDate: end_date,
    operators,
    locations,
    promocodeId,
    includeConvenienceFee
  }) => {
    let mappedData = {
      code,
      description,
      discount_type,
      usage_limit_type,
      promo_code_count,
      max_total_uses,
      max_frequency_limit,
      value,
      percent,
      start_date,
      end_date,
      operators: operators.map((op) => op.value),
      locations: locations.map((location) => location.value),
      include_convenience_fee:includeConvenienceFee
    };
    if (typeof promocodeId !== "undefined") {
      return ApiService.put(`/promo_codes/${code}`, mappedData);
    } else {
      return ApiService.post("/promo_codes", mappedData);
    }
  };

  static uploadCsv = async ({ fileData, locations, operators, csvType }) => {
    let mappedData = {
      csv_file: fileData,
      location_ids: JSON.stringify(locations.map((loc) => loc?.value)),
      operator_ids: JSON.stringify(operators.map((op) => op.value)),
      csv_type: csvType,
    };
    return ApiService.upload("/promo_codes/upload_csv", mappedData);
  };

  static getPromoCode = async (code) => ApiService.get(`/promo_codes/${code}`);

  static getPromoCodeValidity = async ({
    promoCode,
    locationId,
    allowPromocode,
    vehicleLicensePlate
  }) =>
    ApiService.get(`/promo_code/${promoCode}/${locationId}/${allowPromocode}`, { vehicle_plate: vehicleLicensePlate });

  static getPassCodeValidity = async ({ passCode, rate_id }) =>
    ApiService.get(`/pass_code/${rate_id}/${passCode}`);

  static getPromoCodes = () => ApiService.get("/promo_codes");

  static deletePromocode = (code) => ApiService.delete(`/promo_codes/${code}`);
}
