import ApiService from "./ApiService";
export default class TransactionService {
  static getFinanceReport = async () =>
    ApiService.get("payments/finance_report");
  static getLocationPerGroup = async ({ groupId, startDate, endDate }) =>
    ApiService.get(
      `/payments/transactions_per_group/${groupId}?start_date=${startDate}&end_date=${endDate}`
    );

  static getTransactionsPerLocation = async ({
    locationId,
    startDate,
    endDate,
  }) =>
    ApiService.get(
      `/transactions_per_location/${locationId}?start_date=${startDate}&end_date=${endDate}`
    );
  static getLocationTransactionsPerGroup = async ({
    groupId,
    startDate,
    endDate,
  }) =>
    ApiService.get(
      `/payments/locations_transactions/${groupId}?start_date=${startDate}&end_date=${endDate}`
    );
  static getStatementPerGroup = async ({ groupId, startDate, endDate }) =>
    ApiService.get(
      `/payments/statement?group_id=${groupId}&start_date=${startDate}&end_date=${endDate}`
    );
  static getStatementPerLocation = async ({ locationId, startDate, endDate }) =>
    ApiService.get(
      `/payments/statement?location_id=${locationId}&start_date=${startDate}&end_date=${endDate}`
    );
  static getRefundReasons = async () => ApiService.get("/refund_reasons");
  static getPaymentRefunds = async ({ paymentId }) => ApiService.get(`/payment_refunds/${paymentId}`);
  static createRefund = ({
    transactionId,
    paymentId,
    refundReason,
    description,
    amount
  }) => {
    const mappedData = {
      ssl_txn_id: transactionId,
      payment_id: paymentId,
      refund_reason_id: refundReason?.value,
      refund_reason_description: description,
      amount: amount?.toString(),
    };
    return ApiService.post("/refund_transaction", mappedData);
  };
}
