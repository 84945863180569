import ApiService from './ApiService';

export default class AdminService {
  static getAdmins = async (filters) =>
    ApiService.get('/account/admins', filters);

  static createAdmin = ({
    name,
    phone,
    first_name,
    last_name,
    email,
    noOfLocations: no_of_locations,
    noOfParkingLots: no_of_parking_lots,
    address: { street, country, state, city, zip },
    market_name,
    operatorId,
    group_type,
    user_ids,
    images,
    theme_color,
    venue_id,
    club_name,
    club_code,
    venue_name,
    team_id,
    header_message,
    venue_default_location_id
  }) => {
    let mappedData = {
      operator: {
        name,
        first_name,
        last_name,
        phone : Number(phone),
        email,
        no_of_locations,
        no_of_parking_lots,
        group_type,
        user_ids:user_ids? user_ids.map((op) => op.value):[],
        logo_path: images,
        theme_color,
        venue_id,
        club_name,
        club_code,
        venue_name,
        team_id,
        header_message,
        venue_default_location_id
      },
      address: {
        street,
        country,
        state,
        city,
        zip,
      },
      market_name,
    };
    if (typeof operatorId !== 'undefined') {
      return ApiService.put(`/operators/${operatorId}`, mappedData);
    } else {
      return ApiService.post('/operators', mappedData);
    }
  };

  static createIPCAdmin = ({ fname, lname, email }) => {
    const mappedData = {
      firstName: fname,
      lastName: lname,
      email,
    };
    return ApiService.post('/admin/admins', mappedData);
  };

  static getOperator = (operatorId) =>
    ApiService.get(`/operators/${operatorId}`);

  static deleteOperator = (operatorId) =>
    ApiService.delete(`/operators/${operatorId}`);

  static updateOperator = async (id, data) =>
    ApiService.patch(`/operators/${id}`, data);

  static getAccounts = (filters) => {
    return ApiService.get('/operators', filters);
  };

  static switchAccounts = (data) =>
    ApiService.post('/admin/switch-accounts', data);
}