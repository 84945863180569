import { Route, Routes } from "react-router-dom";
import { useRef, lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyles, AppContainer } from "./GlobalStyles";

const DefaultLayout = lazy(() => import('./Layout/DefaultLayout/DefaultLayout'));
const SignupLayout = lazy(() => import('../containers/Layout/SignupLayout'));
const AdminLayout = lazy(() => import('./Layout/AdminLayout/AdminLayout'));
const ParkerLayout = lazy(() => import('./Layout/ParkerLayout/ParkerLayout'));
const PayParkingLayout = lazy(() => import('./Layout/PayParkingLayout/PayParkingLayout'));
const PrivateRoute = lazy(() => import('../components/PrivateRoute'));
const TermsAndCondition = lazy(() => import('../pages/Terms&Conditions'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const ServiceUnavailable = lazy(() => import('../pages/ServiceUnavailable'));
const Loader = lazy(() => import('../components/Loader'));

function App() {
  const appRef = useRef(null);
  return (
    <AppContainer ref={appRef}>
      <ToastContainer />
      <GlobalStyles />
      <Suspense fallback={<Loader/>}>
        <Routes>
          <Route path="/admin/*" element={<PrivateRoute><AdminLayout appRef={appRef} /></PrivateRoute>} />
          <Route path="/parker/*" element={<ParkerLayout appRef={appRef} />} />
          <Route path="/auth/*" element={<SignupLayout />} />
          <Route path="*" element={<DefaultLayout appRef={appRef} />} />
          <Route path="/location/*" element={<PayParkingLayout />} />
          <Route path="/service-unavailable" element={<ServiceUnavailable />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Suspense>
    </AppContainer>
  );
}

export default App;
