import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import messageReducer from './messageSlice';
import operatorsReducer from './operatorsSlice';
import locationsReducer from './locationsSlice';
import ratesReducer from './ratesSlice';
import dashboardReducer from './dashboardSlice';
import parkingSessionReducer from './parkingSessionSlice';
import stripeReducer from './stripeSlice';
import promoCodesSlice from './promoCodesSlice';
import usersSlice from './usersSlice';
import marketsSlice from './marketsSlice';
import transactionSlice from './transactionSlice';
import notesSlice from './notesSlice';
import parkerVehiclesSlice from './parkerVehiclesSlice';
import elavonSlice from './elavonSlice';
import subscriptionReducer from './subscriptionSlice';
import flexparkSlice from './flexparkSlice';
import locationVehiclesSlice from './locationVehiclesSlice';
import integrationsSlice from './integrationsSlice';
import supportSlice from './supportSlice';
import paymentGatewaySlice from './paymentGatewaySlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    message: messageReducer,
    operators: operatorsReducer,
    locations: locationsReducer,
    rates: ratesReducer,
    subscription: subscriptionReducer,
    dashboard: dashboardReducer,
    parkingsession: parkingSessionReducer,
    stripe: stripeReducer,
    promocodes: promoCodesSlice,
    users: usersSlice,
    markets: marketsSlice,
    transactions: transactionSlice,
    notes: notesSlice,
    parkerVehicles: parkerVehiclesSlice,
    elavon: elavonSlice,
    flexpark: flexparkSlice,
    locationVehicles: locationVehiclesSlice,
    integrations: integrationsSlice,
    support:supportSlice,
    paymentGateways: paymentGatewaySlice
  },
});
