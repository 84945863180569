import ApiService from "./ApiService";

export default class SubscriptionService {
  static createSubscription = ({
    published,
    rateName,
    price,
    duration,
    prorate,
    overnightRate,
    availableStart,
    rateType,
    availableEnd,
    availableForPurchase,
    locationId,
    rateId,
  }) => {
    const mappedData = {
      published,
      rate_name: rateName,
      price,
      duration,
      prorate,
      rate_type: rateType,
      overnight_rate: overnightRate,
      available_start: availableStart,
      available_end: availableEnd,
      available_for_purchase: availableForPurchase,
      location_id: locationId,
    };
    if (typeof rateId !== "undefined") {
      return ApiService.put(`/rates/${rateId}`, mappedData);
    } else {
      return ApiService.post("/rates?rate_type=subscription", mappedData);
    }
  };

  static editSubscription = ({ vehicle_license_number, id, ssl_token }) => {
    const mappedData = {
      subscription: { vehicle_license_number, id, ssl_token },
    };
    if (typeof id !== "undefined") {
      return ApiService.put(`/user_subscription/${id}`, mappedData);
    } else {
      return null;
    }
  };

  static getSubscriptions = async () =>
    ApiService.get("/rates?rate_type=subscription");

  static getUserFlexpasses = () => ApiService.get("/parker_flexparks");

  static getMonthlySubscriptions = ({ user_id }) =>
    ApiService.get(`/users/${user_id}/subscriptions`);

  static getUserActiveSubscription = ({ user_id }) =>
    ApiService.get(`/users/${user_id}/active_subscription`);

  static getSubscription = ({ subscription_id }) =>
    ApiService.get(`/subscription/${subscription_id}`);

  static cancelActiveSubscription = ({ id, ssl_recurring_id }) =>
    ApiService.post(
      `/cancel_recurring_payment?id=${id}&ssl_recurring_id=${ssl_recurring_id}`
    );
}
