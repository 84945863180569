import ApiService from './ApiService';

export default class ElavonService {
  static getSessionToken = async (payload) =>
    ApiService.post('/elavon_transaction_token', payload);

  static getCurrentUserParkerCards = async () => ApiService.get('/parker_cards');

  static addParkerCard = async (payload) => ApiService.post('/parker_cards', payload);

  static deleteCurrentUserParkerCard = async ({ id, ssl_token }) => ApiService.delete(`/parker_cards/${id}?ssl_token=${ssl_token}`);

  static processOneTimePayment = async (payload) => ApiService.post('/process_one_time_payment', payload);
}
