/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AdminService from '../services/AdminService';
import LocationService from '../services/LocationService';

export const createOperator = createAsyncThunk(
  'operators/createOperator',
  async ({
    name,
    first_name,
    last_name,
    phone,
    email,
    country,
    street,
    state,
    city,
    zip,
    market_name,
    group_type,
    user_ids,
    operatorId,
    images,
    theme_color,
    venue_id,
    club_name,
    club_code,
    venue_name,
    team_id,
    header_message,
    venue_default_location_id
  }) => {
    try {
      const response = await AdminService.createAdmin({
        name,
        first_name,
        last_name,
        phone,
        email,
        address: {
          street,
          country,
          state,
          city,
          zip,
        },
        market_name,
        group_type,
        user_ids,
        operatorId,
        images,
        theme_color,
        venue_id,
        club_name,
        club_code,
        venue_name,
        team_id,
        header_message,
        venue_default_location_id
      });
      if (
        typeof response !== 'undefined' &&
        typeof response?.data !== 'undefined'
      ) {
        const data = await response?.data;
        if (typeof operatorId !== 'undefined') {
          data['operatorId'] = operatorId;
        }
        return data;
      }
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteOperator = createAsyncThunk(
  'operators/deleteOperator',
  async (operatorId) => {
    try {
      const response = await AdminService.deleteOperator(operatorId);
      const data = response.data;
      data['operatorId'] = operatorId;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateOperator = createAsyncThunk(
  `operators/updateOperator`,
  async (dataObject) => {
    const { id, active } = dataObject;
    const response = await AdminService.updateOperator(id, {
      active,
    });
    const data = response.data;
    data['id'] = id;
    return data;
  }
);

export const getOperators = createAsyncThunk(
  'operators/getOperators',
  async (filters) => {
    const response = await AdminService.getAccounts(filters);
    const data = response.data;
    return data;
  }
);

export const getOperator = createAsyncThunk(
  'operators/getOperator',
  async (operatorId) => {
    if (typeof operatorId !== 'undefined') {
      const response = await AdminService.getOperator(operatorId);
      const data = response.data;
      return data;
    }
  }
);

export const deleteLocation = createAsyncThunk(
  'locations/deleteLocation',
  async (locationId) => {
    const response = await LocationService.deleteLocation(locationId);
    const data = response.data;
    data['locationId'] = locationId;
    return data;
  }
);

export const operatorsSlice = createSlice({
  name: 'operators',
  initialState: {
    loading: false,
    error: null,
    data: [],
    operator: null,
    id: null,
  },
  extraReducers: {
    [createOperator.pending]: (state) => {
      state.loading = true;
    },
    [createOperator.rejected]: (state, action) => {
      state.loading = false;
    },
    [createOperator.fulfilled]: (state, action) => {
      if (typeof action?.payload?.operatorId !== 'undefined') {
        state.loading = false;
        let operators = state.operators.filter(
          (operator) => operator.id !== action?.payload?.operatorId
        );
        operators.push(action.payload);
        state.operators = operators;
      } else if (typeof action?.payload?.message !== 'undefined') {
        state.loading = false;
      } else {
        state.loading = false;
        state?.operators?.push(action.payload);
      }
    },
    [deleteOperator.pending]: (state) => {
      state.loading = true;
    },
    [deleteOperator.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteOperator.fulfilled]: (state, action) => {
      state.loading = false;
      state.operators = state.operators.filter(
        (operator) => operator.id !== action.payload.operatorId
      );
    },
    [getOperators.pending]: (state) => {
      state.loading = true;
    },
    [getOperators.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getOperators.fulfilled]: (state, action) => {
      state.loading = false;
      state.operators = action.payload;
    },
    [getOperator.pending]: (state) => {
      state.loading = true;
    },
    [getOperator.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getOperator.fulfilled]: (state, action) => {
      state.loading = false;
      state.operator = action.payload;
    },
    [deleteLocation.pending]: (state) => {
      state.loading = true;
    },
    [deleteLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteLocation.fulfilled]: (state, action) => {
      state.loading = false;
      state.operator.operator.locations = state.operator.operator.locations.map(
        (location) =>
          location.id === action.payload.locationId
            ? { ...location, active: false }
            : location
      );
    },
    [updateOperator.pending]: (state) => {
      state.loading = true;
    },
    [updateOperator.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updateOperator.fulfilled]: (state, action) => {
      if (typeof action.payload.operator.id !== 'undefined') {
        state.loading = false;
        let operators = state.operators.filter(
          (operator) => operator.id !== action.payload.operator.id
        );
        let updatedOperator = state.operators.filter(
          (operator) => operator.id === action.payload.operator.id
        );
        let elementPos = state.operators.map((x) => x.id).indexOf(action.payload.operator.id);
        let operator = { ...updatedOperator[0], ...action.payload.operator };
        operators.splice(elementPos, 0, operator);
        state.operators = operators;
      }
    },
  },
});

export default operatorsSlice.reducer;
