/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IntegrationsService from "../services/IntegrationsService";

export const createIntegration = createAsyncThunk(
  "integrations/IntegrationsService",
  async ({
    systemName,
    operators,
    locations,
    integrationTypeCd,
    passportOperatorId,
    passportClient,
    passportSecret,
    passportZoneId,
    enabled,
    apiKey,
    apiSecret,
    integrationId,
    parisCustomerCode,
    parisUserId,
    parisUserPassword,
    entryId,
    orgId,
    entryName,
    // systemNameOpenPath,
    password,
    email,
    userId,
    open_path_entry_id_one,
    open_path_entry_name_one,
    integrationsId
  }) => {
    try {
      const response = await IntegrationsService.createIntegration({
        systemName,
        operators,
        locations,
        integrationTypeCd,
        passportOperatorId,
        passportClient,
        passportSecret,
        passportZoneId,
        enabled,
        apiKey,
        apiSecret,
        integrationId,
        parisCustomerCode,
        parisUserId,
        parisUserPassword,
        entryId,
        orgId,
        entryName,
        // systemNameOpenPath,
        password,
        email,
        userId,
        open_path_entry_id_one,
       open_path_entry_name_one,
       integrationsId
      });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        if (typeof integrationId !== "undefined") {
          data["integrationId"] = integrationId;
        }
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);
export const getIntegrations = createAsyncThunk(
  "integrations/getIntegrations",
  async () => {
    const response = await IntegrationsService.getIntegrations();
    const data = response.data;
    return data;
  }
);
export const getSingleIntegrations = createAsyncThunk(
  "integrations/getSingleIntegrations",
  async (integrationsId) => {
    const response = await IntegrationsService.getSingleIntegrations({integrationsId});
    const data = response.data;
    return data;
  }
);
export const getFormValues = createAsyncThunk(
  "integrations/getFormValues",
  async () => {
    const response = await IntegrationsService.getFormValues();
    const data = response.data;
    return data;
  }
);
export const deleteIntegration = createAsyncThunk(
  "integrations/deleteIntegration",
  async (integrationId) => {
    const response = await IntegrationsService.deleteIntegration(integrationId);
    const data = response.data;
    data["integrationId"] = integrationId;
    return data;
  }
);
export const updateIntegration = createAsyncThunk(
  "integrations/updateIntegration",
  async (dataObject) => {
    const { id, enabled } = dataObject;
    const response = await IntegrationsService.updateIntegration(id, {
      enabled,
    });
    const data = response.data;
    data["id"] = id;
    return data;
  }
);

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  extraReducers: {
    [createIntegration.pending]: (state) => {
      state.loading = true;
    },
    [createIntegration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [createIntegration.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getIntegrations.pending]: (state) => {
      state.loading = true;
    },
    [getIntegrations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getIntegrations.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getSingleIntegrations.pending]: (state) => {
      state.loading = true;
    },
    [getSingleIntegrations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getSingleIntegrations.fulfilled]: (state, action) => {
      state.loading = false;
      state["singleIntegration"] = action.payload;
    },
    [getFormValues.pending]: (state) => {
      state.loading = true;
    },
    [getFormValues.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getFormValues.fulfilled]: (state, action) => {
      state.loading = false;
      state.formValues = action.payload;
    },
    [deleteIntegration.pending]: (state) => {
      state.loading = true;
    },
    [deleteIntegration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteIntegration.fulfilled]: (state, action) => {
      state.loading = false;
      state.data.integrations = state.data.integrations.filter(
        (integration) => integration.id !== action.payload.integrationId
      );
    },
    [updateIntegration.pending]: (state) => {
      state.loading = true;
    },
    [updateIntegration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updateIntegration.fulfilled]: (state) => {
      state.loading = false;
    },
  },
});

export default integrationsSlice.reducer;
